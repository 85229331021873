<template>

<div class="title">

	{{ text }}

	<div class="title-label" v-if="label">{{ label }}</div>

</div>

</template>

<script>

export default {

	props: ['text', 'label']

}

</script>

<style scoped>

.title {
	color: #8896aa;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 14px;
	font-weight: 400;
	margin: 30px 0px 10px 0px;
	padding: 0px 20px;
}

.title:first-child {
	margin-top: 20px;
}

.title-label {
	height: 20px;
	flex-shrink: 0;
	border-radius: 4px;
	text-align: center;
	background-color: #4082d3;
	font-weight: 400;
	padding: 0px 4px;
	font-size: 12px;
	text-transform: initial;
	line-height: 20px;
	color: #fff;
	position: absolute;
	right: 10px;
	top: -3px;
}

</style>
